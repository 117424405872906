import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import KybMenu from './KybMenu';
import KybForm from './KybForm';
import { toast } from 'react-toastify';
import kybpending from '../../../../assets/img/kybpending.svg';
import kybsubmitted from '../../../../assets/img/kybsubmitted.svg';
import kybcomplete from '../../../../assets/img/kybcomplete.svg';
import Stack from '@mui/material/Stack';
import { Loader, Button } from 'rsuite';
import {
  getbusinesscategory,
  getbusiesstype,
} from '../../../../redux/actions/businessApi';
import { connect } from 'react-redux';

import { COMPANY_ROLE, ADD_MERCHANT_API } from '../../../../ApiUrl';
import { merchantApi } from '../../../../redux/api/apiConfig';
import { getStatus } from '../../../../redux/actions/kybActions';
import BulletPointer from '../../../../components/BulletPointer';
import { statusColors } from '../../../../utils/common';

const imageStyle = {
  width: '40%',
  maxWidth: '400px',
  minWidth: '300px',
  marginBottom: '2rem',
};

const KYB = ({ getbusinesscategory, getbusiesstype }) => {
  const [stage, setStage] = useState('welcome');
  const [loading, setLoading] = useState(true);
  const [provinces, setProvinces] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [selectedBusinessType, setSelectedBusinessType] = useState(
    'Incorporated Company'
  );
  const [roles, setRoles] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [isMerchantUpdateComplete, setIsMerchantUpdateComplete] =
    useState(false);
  const [initialFormikValues, setInitialFormikValues] = useState({});
  const [merchantData, setMerchantData] = useState({});

  const kybData = useSelector((state) => state.kybReducer);
  const errorMessages = kybData?.status?.data?.action_suggested;
  const initialDocData = kybData.status.data;
  const statusText = kybData?.status?.data?.status_text;
  // const merchantData = useSelector(
  //   (state) => state.profileReducer.user.merchant
  // );

  const countryList = JSON.parse(localStorage.getItem('country-list')) || [];
  const categoryList = JSON.parse(localStorage.getItem('category-list')) || [];
  let user = JSON.parse(localStorage.getItem('user-info'));

  useEffect(() => {
    getbusiesstype();
    getbusinesscategory();
    getRoles();
    getStatus();
    getMerchantDetail();
  }, []);

  useEffect(() => {
    setLoading(true);
    setIsMerchantUpdateComplete(merchantData?.is_update_complete);
    const countryCode = countryList.filter(
      (country) => country.id === merchantData?.country
    )[0];

    setCountryId(countryCode?.id);

    const selectedCountryProvinces = countryList.filter(
      (country) => country.id === merchantData?.country
    )[0]?.province;
    setProvinces(selectedCountryProvinces);
    let subCategoryList;
    if (merchantData?.category?.parent_category) {
      subCategoryList = categoryList.filter(
        (category) => category.id === merchantData?.category?.parent_category
      )[0]?.sub_category;
    } else {
      subCategoryList = [];
    }
    setSubCategoryList(subCategoryList);
    setSelectedBusinessType(merchantData?.business_type?.option);

    let init = {
      country: countryCode?.alpha2code || '',
      province: merchantData?.province || '',
      business_type: merchantData?.business_type?.id || '',
      name: merchantData?.name || '',
      registration_no: merchantData?.registration_no || '',
      role: merchantData?.role || '',
      address: merchantData?.address || '',
      city: merchantData?.city || '',
      postal_code: merchantData?.postal_code || '',
      web_url: merchantData?.web_url || '',
      no_of_store: merchantData?.no_of_store || '',
      parent_category: merchantData?.category?.parent_category || '',
      category: merchantData?.category?.id || '',
      social_security_no: merchantData?.social_security_no || '',
    };
    setInitialFormikValues(init);
    setLoading(false);
  }, [merchantData]);

  let content = '';

  if (statusText.toLowerCase() === 'complete') {
    content = (
      <Stack
        direction="column"
        spacing={3}
        sx={{
          flexGrow: '1',
          textAlign: 'center',
          alignItems: 'center',
          padding: '0 2rem',
        }}
      >
        <img style={imageStyle} src={kybcomplete} alt="completed" srcSet="" />
        <h4>Verification Completed</h4>
        {/* <Button
          onClick={() => setStage('verify')}
          style={{
            minHeight: '40px',
            maxWidth: 'fit-content',
            maxHeight: '40px',
          }}
          appearance="default"
          className="btn load text-white d-flex flex-row gap-2"
        >
          Edit KYB Data
        </Button> */}
      </Stack>
    );
  } else if (statusText.toLowerCase() === 'pending') {
    content = (
      <Stack
        direction="column"
        spacing={3}
        sx={{
          flexGrow: '1',
          textAlign: 'center',
          alignItems: 'center',
          padding: '0 2rem',
        }}
      >
        <h5>Verify your account without delay</h5>
        <img style={imageStyle} src={kybpending} alt="pending" srcSet="" />
        <p className="kyb-info">
          Because it takes responsibility for tax compliance and fraud, we need
          to verify all seller accounts before they can start selling. Account
          verification can take around 4 working days.
        </p>
        <Button
          onClick={() => setStage('verify')}
          style={{
            minHeight: '40px',
            maxWidth: 'fit-content',
            maxHeight: '40px',
          }}
          appearance="default"
          className="btn load text-white d-flex flex-row gap-2"
        >
          Start KYB
        </Button>
      </Stack>
    );
  } else if (statusText.toLowerCase() === 'application submitted') {
    content = (
      <Stack
        direction="column"
        spacing={3}
        sx={{
          flexGrow: '1',
          textAlign: 'center',
          alignItems: 'center',
          padding: '0 2rem',
        }}
      >
        <h5>Verification Pending</h5>
        <img style={imageStyle} src={kybsubmitted} alt="submitted" srcSet="" />
        <p className="kyb-info">
          We’re experiencing high demand at the moment and approvals are taking
          longer than expected. Please bear with us, we hope to review your KYB
          as soon as we can.
        </p>
      </Stack>
    );
  } else if (statusText.toLowerCase() === 'rejected') {
    content = (
      <>
        <Stack
          direction="column"
          spacing={3}
          sx={{
            flexGrow: '1',
            textAlign: 'center',
            alignItems: 'center',
            padding: '0 2rem',
          }}
        >
          <h5>Application Rejected</h5>
          <img
            style={imageStyle}
            src={kybsubmitted}
            alt="submitted"
            srcSet=""
          />

          <Button
            onClick={() => setStage('verify')}
            style={{
              minHeight: '40px',
              maxWidth: 'fit-content',
              maxHeight: '40px',
            }}
            appearance="default"
            className="btn load text-white d-flex flex-row gap-2"
          >
            Edit KYB Data
          </Button>
        </Stack>
      </>
    );
  }

  const getMerchantDetail = () => {
    merchantApi
      .get(ADD_MERCHANT_API)
      .then((response) => {
        setMerchantData(response.data);
        // setMerchantDetail(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message || 'Something went wrong');
      });
  };

  const getRoles = () => {
    // setLoading(true);

    merchantApi
      .get(COMPANY_ROLE)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Something went wrong');
      });
  };

  return (
    <>
      {' '}
      <div className="page-wrapper stb-page-wrapper stb-ph-8">
        <div className="tabing stb-h-100">
          <div className="reports bg-white p-xl-4 p-3 radi stb-h-100">
            <div className="d-flex align-items-center pb-4 space-between">
              <div>
                <h3 className="m-0 page-title">Welcome {user.first_name}!</h3>
                <p>
                  {kybData?.status?.data?.status_text?.toLowerCase() ===
                  'complete'
                    ? 'Congratulations, you have completed your KYB process.'
                    : 'Complete the following steps to finish your setup.'}
                </p>
              </div>
            </div>
            <section className="kyb-container">
              <KybMenu
                stage={stage}
                statusText={kybData?.status?.data?.status_text}
              />
              {stage === 'welcome' ? (
                <>{content}</>
              ) : !loading ? (
                <KybForm
                  pProvinces={provinces}
                  pSubCategoryList={subCategoryList}
                  pSelectedBusinessType={selectedBusinessType}
                  pInitialFormikValues={initialFormikValues}
                  initialDocData={initialDocData}
                  pRoles={roles}
                  stage={stage}
                  setStage={setStage}
                  isVerified={kybData?.status?.data?.is_kyc_verified}
                  status={kybData?.status?.data?.status}
                  statusText={kybData?.status?.data?.status_text}
                  kybData={kybData}
                  countryId={countryId}
                  isMerchantUpdateComplete={isMerchantUpdateComplete}
                />
              ) : (
                ''
              )}
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    data: state.kybReducer.data,
    category: state.businessReducer.category,
    businesstype: state.businessReducer.businessType,
  };
}

const actionCreators = {
  getbusiesstype,
  getbusinesscategory,
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(KYB);
