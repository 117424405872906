import React, { useState, useEffect } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { Col, Row } from '@themesberg/react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { getStatus } from '../../../../redux/actions/kybActions';
import axios from 'axios';
import { SET_PROFILE } from '../../../../redux/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, Button } from 'rsuite';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { TextField, MenuItem } from '@material-ui/core';
import { textFieldLabelProps } from '../../../../utils/common';

import {
  ADD_MERCHANT_API,
  MERCHANT_PROFILE,
  GET_DOCUMET_TYPE,
  KYB_START,
  UPLOAD_KYB_DOC,
} from '../../../../ApiUrl';
import { merchantApi } from '../../../../redux/api/apiConfig';

const kybValidation = yup.object({
  country: yup.string('Select country'),
  province: yup.string('Select province'),
  business_type: yup.string('Select company type'),
  name: yup.string('Enter your business name'),
  // .min(2, 'Please enter you real name')
  registration_number: yup
    .string(' Enter registration number')
    .when('business_type', {
      is: (val) => {
        if (['2', '3', '4'].includes(val)) {
          return val;
        } else return '1';
      },
      then: yup.string(' Enter registration number'),
    }),

  role: yup.string('Select company role').when('business_type', {
    is: '1',
    then: yup.string('Enter company role'),
  }),
  address: yup.string('Enter address'),
  city: yup.string('Enter city'),
  postal_code: yup.string(' Enter postal code'),
  website: yup.string().url('Please enter a valid URL'),
  no_of_store: yup
    .number('Enter your stores count')
    .min(1, 'Should have minimum one store')
    .positive('Enter valid stores count')

    .integer(),

  parent_category: yup.string('Enter your business category'),
  category: yup.string('Enter your business subcategory'),
  social_security_no: yup.string(' Enter social security number'),
});

const UpdateBusinessInfo = ({
  pInitialFormikValues,
  countryId,
  pProvinces,
  pSelectedBusinessType,
  pRoles,
  pSubCategoryList,
  handleNext,
  onBackClick,
  setBusinessInfo,
  getStatus,
}) => {
  const dispatch = useDispatch();
  let token = localStorage.getItem('token');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  console.log(pInitialFormikValues);

  const [updatingMerchantInfo, setUpdatingMerchantInfo] = useState(false);
  const [businessCountry, setBusinessCountry] = useState(countryId);
  const [provinces, setProvinces] = useState(pProvinces);
  const [subCategoryList, setSubCategoryList] = useState(pSubCategoryList);
  const [selectedBusinessType, setSelectedBusinessType] = useState(
    pSelectedBusinessType
  );
  const [selectedCategory, setSelectedCategory] = useState();

  const businessTypes = useSelector(
    (state) => state.businessReducer.businessType
  );

  const updateProfileRedux = async () => {
    try {
      const response = await axios.get(`${MERCHANT_PROFILE}profile/`, config);
      const { data } = response;
      dispatch({ type: SET_PROFILE, payload: data.data });
    } catch (error) {
      toast.error(error.response.data.message || 'Something went wrong');
    }
  };
  const onSubmit = () => {
    setUpdatingMerchantInfo(true);
    const data = {
      country: businessCountry,
      province: kybFormik.values.province,
      address: kybFormik.values.address,
      city: kybFormik.values.city,
      postal_code: kybFormik.values.postal_code,
      name: kybFormik.values.name,
      no_of_store: kybFormik.values.no_of_store,
      parent_category: kybFormik.values.parent_category,
      category: kybFormik.values.category,
      registration_no: kybFormik.values.registration_number,
      business_type: kybFormik.values.business_type,
      role: kybFormik.values.role,
      web_url: kybFormik.values.website,
      social_security_no: kybFormik.values.social_security_no,
    };
    merchantApi
      .patch(ADD_MERCHANT_API, {
        ...data,
        category: kybFormik.values.category,
      })
      .then((response) => {
        const countryCode = countryList.filter(
          (country) => country.id === businessCountry
        )[0];
        getStatus();
        updateProfileRedux();
        toast.success('Merchant data updated successfully');
        setUpdatingMerchantInfo(false);
        setBusinessInfo({ ...data, country: countryCode.alpha2code });
        handleNext();
      })
      .catch((err) => {
        setUpdatingMerchantInfo(false);
        console.log(err);
        // toast.error(err.data.data[Object.keys(err.data.data)[0]][0]);
      });
  };
  let kybFormik = useFormik({
    initialValues: pInitialFormikValues,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: onSubmit,
    validationSchema: kybValidation,
  });

  const countryList = JSON.parse(localStorage.getItem('country-list')) || [];
  const categoryList = JSON.parse(localStorage.getItem('category-list')) || [];

  const countryCodes = countryList.reduce((acc, cur) => {
    acc.push(cur.alpha2code);
    return acc;
  }, []);

  useEffect(() => {
    console.log(1);
    if (selectedCategory) {
      const selectedCategoryItem = _.find(categoryList, {
        id: selectedCategory.value,
      });
      setSubCategoryList(selectedCategoryItem.sub_category);
    }
  }, [selectedCategory]);

  return (
    <>
      <form>
        <Row>
          <Col xl={4} sm={4} xs={12}>
            <ReactFlagsSelect
              countries={countryCodes}
              className="mt-4 bg-white material-inp"
              style={{ lineHeight: '2rem' }}
              selected={kybFormik.values.country}
              onSelect={(code) => {
                let countryId = _.find(countryList, {
                  alpha2code: code,
                }).id;

                setBusinessCountry(countryId);

                let e = {
                  target: {
                    name: 'country',
                    value: code,
                  },
                };
                kybFormik.handleChange(e);
                const selectedCountryProvinces = countryList.filter(
                  (country) => country.alpha2code === code
                )[0].province;

                setProvinces(selectedCountryProvinces);
              }}
            />
          </Col>

          <Col xl={4} sm={4} xs={12}>
            <TextField
              select
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="province"
              name="province"
              InputLabelProps={textFieldLabelProps}
              label="Province"
              value={kybFormik.values.province}
              onChange={(event) => {
                kybFormik.handleChange(event);
              }}
              onBlur={(event) => {
                kybFormik.handleChange(event);
              }}
              error={
                kybFormik.touched.province && Boolean(kybFormik.errors.province)
              }
              helperText={
                kybFormik.touched.province && kybFormik.errors.province
              }
            >
              {provinces.map((province) => (
                <MenuItem value={province.id}>{province.name}</MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xl={4} sm={4} xs={12}>
            <TextField
              select
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="business_type"
              name="business_type"
              InputLabelProps={textFieldLabelProps}
              label="Company Type"
              value={kybFormik.values.business_type}
              onChange={(event) => {
                let businessType = _.find(businessTypes, {
                  id: event.target.value,
                }).option;
                setSelectedBusinessType(businessType);
                kybFormik.handleChange(event);
              }}
              onBlur={(event) => {
                kybFormik.handleChange(event);
              }}
              error={
                kybFormik.touched.business_type &&
                Boolean(kybFormik.errors.business_type)
              }
              helperText={
                kybFormik.touched.business_type &&
                kybFormik.errors.business_type
              }
            >
              {businessTypes.map((businessType) => (
                <MenuItem value={businessType.id}>
                  {businessType.option}
                </MenuItem>
              ))}
            </TextField>
          </Col>
          <div className="break"></div>
          <Col xl={6} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="name"
              name="name"
              InputLabelProps={textFieldLabelProps}
              label="Business Name"
              value={kybFormik.values.name}
              onChange={kybFormik.handleChange}
              onBlur={kybFormik.handleBlur}
              error={kybFormik.touched.name && Boolean(kybFormik.errors.name)}
              helperText={kybFormik.touched.name && kybFormik.errors.name}
            />
          </Col>
          <Col xl={6} sm={6} xs={12}>
            {selectedBusinessType === 'Incorporated Company' ? (
              <TextField
                fullWidth
                variant="outlined"
                className="mt-4 bg-white"
                id="registration_number"
                name="registration_number"
                InputLabelProps={textFieldLabelProps}
                type="text"
                label="Registration Number"
                value={kybFormik.values.registration_number}
                onChange={kybFormik.handleChange}
                onBlur={kybFormik.handleBlur}
                error={
                  kybFormik.touched.registration_number &&
                  Boolean(kybFormik.errors.registration_number)
                }
                helperText={
                  kybFormik.touched.registration_number &&
                  kybFormik.errors.registration_number
                }
              />
            ) : (
              <TextField
                select
                fullWidth
                variant="outlined"
                className="mt-4 bg-white"
                id="role"
                name="role"
                InputLabelProps={textFieldLabelProps}
                label="Company Role"
                value={kybFormik.values.role}
                onChange={(event) => {
                  kybFormik.handleChange(event);
                }}
                onBlur={(event) => {
                  kybFormik.handleChange(event);
                }}
                error={kybFormik.touched.role && Boolean(kybFormik.errors.role)}
                helperText={kybFormik.touched.role && kybFormik.errors.role}
              >
                {pRoles.map((role) => (
                  <MenuItem value={role.id}>{role.name}</MenuItem>
                ))}
              </TextField>
            )}
          </Col>
          <Col xl={6} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="address"
              name="address"
              InputLabelProps={textFieldLabelProps}
              label="Address"
              value={kybFormik.values.address}
              onChange={kybFormik.handleChange}
              onBlur={kybFormik.handleBlur}
              error={
                kybFormik.touched.address && Boolean(kybFormik.errors.address)
              }
              helperText={kybFormik.touched.address && kybFormik.errors.address}
            />
          </Col>
          <Col xl={6} sm={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="city"
              name="city"
              InputLabelProps={textFieldLabelProps}
              label="City"
              value={kybFormik.values.city}
              onChange={kybFormik.handleChange}
              onBlur={kybFormik.handleBlur}
              error={kybFormik.touched.city && Boolean(kybFormik.errors.city)}
              helperText={kybFormik.touched.city && kybFormik.errors.city}
            />
          </Col>
          <div className="break"></div>
          <Col xl={4} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="postal_code"
              name="postal_code"
              InputLabelProps={textFieldLabelProps}
              label="Postal Code"
              value={kybFormik.values.postal_code}
              onChange={kybFormik.handleChange}
              onBlur={kybFormik.handleBlur}
              error={
                kybFormik.touched.postal_code &&
                Boolean(kybFormik.errors.postal_code)
              }
              helperText={
                kybFormik.touched.postal_code && kybFormik.errors.postal_code
              }
            />
          </Col>
          <Col xl={4} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="website"
              name="website"
              InputLabelProps={textFieldLabelProps}
              label="Website (Optional)"
              value={kybFormik.values.website}
              onChange={kybFormik.handleChange}
              onBlur={kybFormik.handleBlur}
              error={
                kybFormik.touched.website && Boolean(kybFormik.errors.website)
              }
              helperText={kybFormik.touched.website && kybFormik.errors.website}
            />
          </Col>
          <Col xl={4} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="no_of_store"
              name="no_of_store"
              InputLabelProps={textFieldLabelProps}
              type="number"
              label="Business Size"
              value={kybFormik.values.no_of_store}
              onChange={kybFormik.handleChange}
              onBlur={kybFormik.handleBlur}
              error={
                kybFormik.touched.no_of_store &&
                Boolean(kybFormik.errors.no_of_store)
              }
              helperText={
                kybFormik.touched.no_of_store && kybFormik.errors.no_of_store
              }
            />
          </Col>
          <Col xl={4} sm={4} xs={12}>
            <TextField
              select
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="parent_category"
              name="parent_category"
              InputLabelProps={textFieldLabelProps}
              label="Business Category"
              value={kybFormik.values.parent_category}
              onChange={(event) => {
                setSelectedCategory(event.target);
                kybFormik.setFieldValue('category', '');
                kybFormik.handleChange(event);
              }}
              onBlur={(event) => {
                kybFormik.handleChange(event);
              }}
              error={
                kybFormik.touched.parent_category &&
                Boolean(kybFormik.errors.parent_category)
              }
              helperText={
                kybFormik.touched.parent_category &&
                kybFormik.errors.parent_category
              }
            >
              {categoryList.map((category) => (
                <MenuItem value={category.id}>{category.name}</MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xl={4} sm={4} xs={12}>
            <TextField
              select
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="category"
              name="category"
              InputLabelProps={textFieldLabelProps}
              label="Business Subcategory"
              value={kybFormik.values.category}
              onChange={kybFormik.handleChange}
              onBlur={kybFormik.handleBlur}
              error={
                kybFormik.touched.category && Boolean(kybFormik.errors.category)
              }
              helperText={
                kybFormik.touched.category && kybFormik.errors.category
              }
            >
              {subCategoryList.map((subCategory) => (
                <MenuItem value={subCategory.id}>{subCategory.name}</MenuItem>
              ))}
            </TextField>
          </Col>
          <Col xl={4} sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              className="mt-4 bg-white"
              id="social_security_no"
              name="social_security_no"
              InputLabelProps={textFieldLabelProps}
              type="text"
              label="Social Security Number"
              value={kybFormik.values.social_security_no}
              onChange={kybFormik.handleChange}
              onBlur={kybFormik.handleBlur}
              error={
                kybFormik.touched.social_security_no &&
                Boolean(kybFormik.errors.social_security_no)
              }
              helperText={
                kybFormik.touched.social_security_no &&
                kybFormik.errors.social_security_no
              }
            />
          </Col>
        </Row>
      </form>
      <footer>
        <Button
          className="btn white-btn action-button basis-30"
          onClick={onBackClick}
          appearance="subtle"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            kybFormik.handleSubmit();
          }}
          loading={updatingMerchantInfo}
          disabled={!kybFormik.isValid}
          style={{ minHeight: '40px', maxWidth: 'fit-content' }}
          appearance="primary"
          className={`btn action-button primary_color text-white ${
            !kybFormik.isValid ? 'disabled' : ''
          }`}
        >
          <span>Update and continue</span>
        </Button>
      </footer>
    </>
  );
};

function mapStateToProps({ kybReducer }) {
  const { status } = kybReducer;
  return {
    data: status.data,
  };
}

const actionCreators = {
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(UpdateBusinessInfo);
