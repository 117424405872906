import React, { useState, useEffect } from 'react';
import kybpending from '../../../../assets/img/kybpending.svg';
import kybsubmitted from '../../../../assets/img/kybsubmitted.svg';
import kybcomplete from '../../../../assets/img/kybcomplete.svg';
// import { Button } from '@themesberg/react-bootstrap';
import { Loader, Button } from 'rsuite';
import ReactFlagsSelect from 'react-flags-select';
import { TextField, MenuItem } from '@material-ui/core';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as yup from 'yup';
import { Col, Row } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import {
  ADD_MERCHANT_API,
  MERCHANT_PROFILE,
  GET_DOCUMET_TYPE,
  KYB_START,
  UPLOAD_KYB_DOC,
} from '../../../../ApiUrl';
import { merchantApi } from '../../../../redux/api/apiConfig';
import { useHistory } from 'react-router-dom';

import { textFieldLabelProps } from '../../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { getStatus } from '../../../../redux/actions/kybActions';
import { connect } from 'react-redux';
import back from '../../../../assets/img/icons/back.svg';
import axios from 'axios';
import { SET_PROFILE } from '../../../../redux/actionTypes';
import UpdateBusinessInfo from './UpdateBusinessInfo';
import UploadKybDocs from './UploadKybDocs';
import KybPreview from './KybPreview';

const kybValidation = yup.object({
  country: yup.string('Select country'),
  province: yup.string('Select province'),
  company_type: yup.string('Select company type'),
  business_name: yup.string('Enter your business name'),
  // .min(2, 'Please enter you real name')
  registration_number: yup
    .string(' Enter registration number')
    .when('company_type', {
      is: (val) => {
        if (['2', '3', '4'].includes(val)) {
          return val;
        } else return '1';
      },
      then: yup.string(' Enter registration number'),
    }),

  company_role: yup.string('Select company role').when('company_type', {
    is: '1',
    then: yup.string('Enter company role'),
  }),
  address: yup.string('Enter address'),
  city: yup.string('Enter city'),
  postal_code: yup.string(' Enter postal code'),
  website: yup.string().url('Please enter a valid URL'),
  business_size: yup
    .number('Enter your stores count')
    .min(1, 'Should have minimum one store')
    .positive('Enter valid stores count')

    .integer(),

  business_category: yup.string('Enter your business category'),
  business_subcategory: yup.string('Enter your business subcategory'),
  social_security_number: yup.string(' Enter social security number'),
});

const KybForm = ({
  stage,
  setStage,
  isVerified,
  status,
  kybData,
  pProvinces = [],
  pSubCategoryList = [],
  pSelectedBusinessType = [],
  pInitialFormikValues,
  pRoles,
  countryId,
  initialDocData,
  getStatus,
  statusText,
  isMerchantUpdateComplete,
}) => {
  const [editable, seteditable] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  // const [skipped, setSkipped] = React.useState(new Set());

  const [businessInfo, setBusinessInfo] = useState(pInitialFormikValues);
  const [docInfo, setDocInfo] = useState({
    fileData: initialDocData,
    frontUrl: initialDocData.front_part_of_document,
    backUrl: initialDocData.back_part_of_document,
    docType: initialDocData.document_type,
    frontEdit: false,
    backEdit: false,
    docTypeEdit: false,
  });

  const onBackClick = () => {
    setStage('welcome');
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    seteditable(true);
    setActiveStep(activeStep - 1);
  };

  const getcompleted = (r) => {
    return r < activeStep;
  };

  function getStepContent(step) {
    // let step = 2;
    switch (step) {
      case 0:
        return (
          <UpdateBusinessInfo
            editable={editable}
            pInitialFormikValues={businessInfo}
            countryId={countryId}
            pProvinces={pProvinces}
            pSelectedBusinessType={pSelectedBusinessType}
            pRoles={pRoles}
            pSubCategoryList={pSubCategoryList}
            handleNext={() => handleNext()}
            onBackClick={onBackClick}
            setBusinessInfo={setBusinessInfo}
          />
        );
      case 1:
        return (
          <UploadKybDocs
            setDocInfo={setDocInfo}
            docInfo={docInfo}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
          />
        );
      case 2:
        return (
          <KybPreview
            setStage={setStage}
            docInfo={docInfo}
            businessInfo={businessInfo}
            handleNext={() => handleNext()}
            handleBack={() => handleBack()}
          />
        );
      // case 3:
      // return <ThirdStep setformfilling={() => changeView()} />;
      default:
        return 'Unknown step';
    }
  }

  return (
    <section className="kyb-form">
      <Stepper activeStep={activeStep}>
        <Step completed={getcompleted(0)}>
          <StepLabel>
            <span className="d-none d-sm-block">Business Info</span>
          </StepLabel>
        </Step>

        <Step completed={getcompleted(1)}>
          <StepLabel>
            <span className="d-none d-sm-block">Upload Documents</span>
          </StepLabel>
        </Step>

        <Step completed={getcompleted(2)}>
          <StepLabel>
            <span className="d-none d-sm-block">Preview</span>
          </StepLabel>
        </Step>

        {/* <Step completed={getcompleted(3)}>
          <StepLabel>
            <span className="d-none d-sm-block">Successful</span>
          </StepLabel>
        </Step> */}
      </Stepper>

      <article>{getStepContent(activeStep)}</article>
    </section>
  );
};

function mapStateToProps({ kybReducer }) {
  const { status } = kybReducer;
  return {
    data: status.data,
  };
}

const actionCreators = {
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(KybForm);
