import React from 'react';
import emailimg from '../../assets/img/email.gif';
import { Link } from 'react-router-dom';
import { Button } from 'rsuite';

import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@mui/material';

import mail from '../../assets/img/icons/mail.svg';
import maillogo from '../../assets/img/icons/maillogo.svg';
import stblogo from '../../assets/img/icons/stblogo.svg';
import { RESEND_PASSWORD_MAIL } from '../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';

const SignupThanks = (props) => {
  const {
    location: { state },
  } = props;

  console.log(state);
  console.log(state.response.data.data.id);

  const token = state.response.data.token;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const history = useHistory();

  const resendPasswordMail = async () => {
    console.log(state.response.data.data.id);
    const response = await axios.get(
      `${RESEND_PASSWORD_MAIL}${state.response.data.data.id}`,
      config
    );
    if (response.status === 200) {
      toast.success('Mail sent successfully.');
    }
    console.log(response);
  };

  const navigateToLogin = () => {
    history.push('/signin');
  };

  return (
    <section className="primary_color vh-lg-100 position-relative">
      <img
        src={stblogo}
        className="position-absolute top-5 start-50 translate-middle"
        alt="logo"
      />

      <a
        className="socials twitter"
        href="https://twitter.com/Spend_The_Bits"
        target="_blank"
        rel="noopener noreferrer"
      >
        @Spend_The_Bits
      </a>
      <a
        className="socials mail"
        href="mailto:help@spendthebits.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={mail} alt="mail" />
        help@spendthebits.com
      </a>
      <Dialog
        open={true}
        disableEscapeKeyDown={true}
        // onClose={handleClose}
        fullWidth
        className="wallet-configure"
        BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0)' } }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: '8px',
            width: '480px',
            minHeight: '428px',
            maxHeight: '80vh',
          },
        }}
      >
        <DialogTitle className="text-center">
          <p className="dialog-title fw-bold">
            A temporary password has been emailed
          </p>
          <p>Check your mail</p>
        </DialogTitle>
        <DialogContent className="merchant-wallet">
          <div>
            <img style={{ margin: '2rem 11rem' }} src={maillogo} alt="mail" />
          </div>
          <p className="text-center">
            <b className="mb-3">
              We just sent you a temporary password. Please check your inbox
              including spam folder.
            </b>
          </p>
        </DialogContent>
        <DialogActions className="footer-content">
          <Button
            className="btn white-btn action-button grow-1"
            onClick={resendPasswordMail}
          >
            Resend
          </Button>
          <Button
            className={`btn action-button primary_color text-white grow-1`}
            onClick={navigateToLogin}
          >
            Login Now
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default SignupThanks;
