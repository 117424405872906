import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import ButtonPrimary from "../../../components/ButtonPrimary";
import ButtonSecondary from "../../../components/ButtonSecondary";
import * as yup from "yup";
import { merchantApi } from "../../../redux/api/apiConfig";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  GET_MERCHENT_WALLET_LIST,
  TRANS_STORE,
  TRANSACTION_VERIFY_WITHDRAW_ADDRESS,
  WITHDRAW,
  WITHDRAW_FEE
} from "../../../ApiUrl";
import { useFormik } from "formik";
import {
  formatCurrency,
  statusColors,
  textFieldLabelProps,
} from "../../../utils/common";
import InteracDetailsCard from "../../../components/cards/InteracDetailsCard";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { send } from "../../../utils/xrpl_functions";
import BlockchainWithdrawDetailsCard from "../../../components/cards/BlockchainWithdrawDetailsCard";
import SyncAltRoundedIcon from "@mui/icons-material/SyncAltRounded";

const WithdrawModal = ({
  handleCloseModal,
  isOpen,
  onClose,
  isSingleWallet,
  userRole,
  userCurrency,
}) => {
  console.log("hrehre");
  const [createWithdrawLoading, setCreateWithdrawLoading] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [showWalletList, setShowWalletList] = useState([]);
  const [storeOption, setStoreOption] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedWallet, setSelectedWallet] = useState("");
  const [selectedDepositType, setSelectedDepositType] = useState(null);
  const [showWallet, setShowWallet] = useState(isSingleWallet ? true : false);
  const [storeId, setStoreID] = useState("");
  const [coinSearchTerm, setCoinSearchTerm] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [fees, setFees] = useState({handler_fee:0, stb_fee:0})

  // const [random, setRandom] = useState();
  const [coin, setCoin] = useState(null);
  const [selectedBlockchainNetwork, setSelectedBlockchainNetwork] =
    useState(null);

  const [showPassphrase, setShowPassphrase] = useState(false);
  const [walletFetchingLoading, setWalletFetchingLoading] = useState(true);

  const [step, setStep] = useState(isSingleWallet ? 2 : 1);

  useEffect(() => {
    getWalletMenu();
    getStoreList();
    // getCoinList();
  }, []);

  const getWalletMenu = async () => {
    setWalletFetchingLoading(true);
    try {
      const response = await merchantApi.get(
        `${GET_MERCHENT_WALLET_LIST}?limit=100`
      );
      setWalletFetchingLoading(false);
      if (response.data) {
        console.log(response.data);
        setWalletList(response.data.results);
        setShowWalletList(response.data.results);
      }
    } catch (error) {}
  };

  const getStoreList = async () => {
    try {
      const response = await merchantApi.get(`${TRANS_STORE}`);
      setStoreOption(response.data.data);
      // To delay until wallet list is fetched
      // setTimeout(() => {
      // }, 2500);
    } catch (error) {}
  };

  const handleStorechange = (e) => {
    // If user changes store after previously selecting a store and wallet, we need to reset the wallet and coin
    setSelectedWallet("");
    setCoin(null);
    setStoreID(e.target.value);
    setSelectedStore(e.target.value);
    const selectedStoreWallets = showWalletList.filter(
      (item) => item.store.store_id === e.target.value
    );
    if (selectedStoreWallets.length > 0) {
      setWalletList(selectedStoreWallets);
    } else {
      setWalletList([]);
    }
    setShowWallet(true);
  };

  const interacValidationSchema = yup.object({
    amount: yup.number("Enter your amount").required("Amount is required"),
    toAddress: yup
      .string("Enter destination address")
      .required("Address is required"),
    description: yup
      .string("Enter description")
      .min(5, "Too Short")
      .max(300, "Too Long "),
  });

  const blockchainValidationSchema = yup.object({
    currencyAmount: yup.number("Enter your amount").required("Amount is required").moreThan(0, "Amount must be greater than 0"),
    localCurrencyAmount: yup.number("Enter your amount").required(
      "Amount is required"
    ).moreThan(0, "Amount must be greater than 0"),
    toAddress: yup
      .string("Enter destination address")
      .required("Address is required"),
    // description: yup
    //   .string("Enter description")
    //   .min(5, "Too Short")
    //   .max(300, "Too Long "),
  });

  const passphraseValidationSchema = yup.object({
    passphrase: yup
      .string("Enter your passphrase")
      .required("Passphrase is required"),
  });

  const interacFormik = useFormik({
    initialValues: {
      amount: "",
      toAddress: "",
      description: "",
    },
    validateOnBlur: true,
    validationSchema: interacValidationSchema,
    isInitialValid: false,
    validateOnMount: true,
  });

  const blockchainFormik = useFormik({
    initialValues: {
      currencyAmount: "",
      localCurrencyAmount: "",
      toAddress: "",
    },
    validateOnBlur: true,
    validationSchema: blockchainValidationSchema,
    isInitialValid: false,
    validateOnMount: true,
  });

  const passphraseFormik = useFormik({
    initialValues: {
      passphrase: "",
    },
    validateOnBlur: true,
    validationSchema: passphraseValidationSchema,
    isInitialValid: false,
    validateOnMount: true,
  });

  const handleSelectWallet = (wallet) => {
    setSelectedWallet(wallet);
    if (wallet?.coin?.is_withdraw_available) {
      if (wallet?.coin?.deposit_types.length === 0) {
        toast.error("No withdraw methods available");
      } else if (wallet?.coin?.deposit_types?.length === 1) {
        handleSelectDepositType(wallet?.coin?.deposit_types[0], wallet);
      } else {
        console.log("wssss", wallet);
        setStep(3);
      }
    } else {
      toast.error(wallet?.coin?.withdraw_error_message);
    }
  };

  const handleSelectDepositType = (item, wallet = selectedWallet) => {
    console.log("eeeeeeee", item, wallet);
    setSelectedDepositType(item);
    if (item?.key === "interac") {
      setStep("interac");
    } else {
      if (wallet?.coin?.supported_deposit_blockchains?.length === 1) {
        handleSelectBlockchainNetwork(
          wallet?.coin?.supported_deposit_blockchains[0]
        );
      } else if (wallet?.coin?.supported_deposit_blockchains?.length === 0) {
        toast.error("No supported blockchain found");
      } else {
        setStep(4);
      }
    }
  };

  const handleSelectBlockchainNetwork = (item) => {
    setSelectedBlockchainNetwork(item);
    setStep("blockchain");
    // getDepositAddress(selectedWallet, item);
  };

  const interacSubmit = async (values) => {
    let verifiedAddress = null;
    if ((Number(interacFormik.values.amount) + Number(fees.handler_fee) + Number(fees.stb_fee)) > Number(selectedWallet?.balance)) {
      toast.error("Insufficient balance");
      return;
    }

    try {
      setVerificationLoading(true);
      const response = await merchantApi.post(
        `${TRANSACTION_VERIFY_WITHDRAW_ADDRESS}?limit=100`,
        {
          coin: selectedWallet?.coin?.id,
          address: interacFormik?.values?.toAddress,
        }
      );
      verifiedAddress = response.data.data.destination;
      setVerificationLoading(false);
      setDestinationAddress(verifiedAddress);
      toast.success("Address verified!");
    } catch (err) {
      console.log(err);
      setVerificationLoading(false);
      toast.error(err?.data?.data?.message || "Invalid Destination Address");
      return;
    }
    const requestData = {
      amount: Number(interacFormik.values.amount),
      coin: selectedWallet?.coin?.id,
      withdraw_address: interacFormik.values.toAddress,
      stb_fee: fees?.stb_fee,
      handler_fee: fees?.handler_fee,
    };
    if (userRole === "administrator") {
      setStep("passphrase");
    } else {
      try {
        const response = await merchantApi.post(
          `${WITHDRAW}${selectedDepositType?.key}/`,
          requestData
        );
        toast.success("Withdraw request sent!");
      } catch (error) {
        toast.error(
          error.data.data.message || "Request failed, try again later"
        );
      }
    }
  };

  const blockchainSubmit = async (values) => {
    let verifiedAddress = null;
    if ((Number(blockchainFormik.values.currencyAmount)+Number(fees.handler_fee) + Number(fees.stb_fee)) > Number(selectedWallet?.balance)) {
      toast.error("Insufficient balance");
      return;
    }

    try {
      setVerificationLoading(true);
      const response = await merchantApi.post(
        `${TRANSACTION_VERIFY_WITHDRAW_ADDRESS}?limit=100`,
        {
          coin: selectedWallet?.coin?.id,
          address: blockchainFormik?.values?.toAddress,
        }
      );
      verifiedAddress = response.data.data.destination;
      setVerificationLoading(false);
      setDestinationAddress(verifiedAddress);
      toast.success("Address verified!");
    } catch (err) {
      console.log(err);
      setVerificationLoading(false);
      toast.error(err?.data?.data?.message || "Invalid Destination Address");
      return;
    }
    const requestData = {
      amount: Number(blockchainFormik.values.currencyAmount),
      coin: selectedWallet?.coin?.id,
      withdraw_address: blockchainFormik.values.toAddress,
      stb_fee: fees?.stb_fee,
      handler_fee: fees?.handler_fee,
    };
    if (userRole === "administrator") {
      setStep("passphrase");
    } else {
      try {
        const response = await merchantApi.post(
          `${WITHDRAW}${selectedDepositType?.key}/`,
          requestData
        );
        toast.success("Withdraw request sent!");
      } catch (error) {
        toast.error(
          error.data.data.message || "Request failed, try again later"
        );
      }
    }
  };

  const toggleShowPassphrase = () => {
    setShowPassphrase(!showPassphrase);
  };

  const interacPassphraseSubmit = async () => {
    setCreateWithdrawLoading(true)
    const xrpWallet = walletList.filter((item) => item.coin.symbol === "XRP");

    const blob = send(
      xrpWallet[0].address,
      destinationAddress,
      passphraseFormik.values.passphrase,
      Number(interacFormik.values.currencyAmount) + Number(fees.handler_fee) + Number(fees?.stb_fee),
      Number(interacFormik.values.currencyAmount) + Number(fees.handler_fee) + Number(fees?.stb_fee),
      selectedWallet?.coin?.symbol,
      selectedWallet?.coin?.symbol,
      selectedWallet?.coin?.issuer_address,
      selectedWallet?.coin?.issuer_address,
      selectedWallet?.coin?.decimal_place,
    );

    blob.then((res) => {
      const data = {
        method: selectedDepositType?.key,
        requestData: {
          amount: Number(interacFormik.values.amount),
          coin: selectedWallet?.coin?.id,
          blob: res,
          withdraw_address: interacFormik.values.toAddress,
          stb_fee: fees?.stb_fee,
          handler_fee: fees?.handler_fee,
        },
      };
      console.log(res, "asdadsa");
      createWithdraw(data);
    }).catch(err => {
      setCreateWithdrawLoading(false)
    });
  };

  const blockchainPassphraseSubmit = async () => {

    setCreateWithdrawLoading(true)
    const xrpWallet = walletList.filter((item) => item.coin.symbol === "XRP");

    const blob = send(
      xrpWallet[0].address,
      destinationAddress,
      passphraseFormik.values.passphrase,
      Number(blockchainFormik.values.currencyAmount) + Number(fees.handler_fee) + Number(fees?.stb_fee),
      Number(blockchainFormik.values.currencyAmount) + Number(fees.handler_fee) + Number(fees?.stb_fee),
      selectedWallet?.coin?.symbol,
      selectedWallet?.coin?.symbol,
      selectedWallet?.coin?.issuer_address,
      selectedWallet?.coin?.issuer_address,
      selectedWallet?.coin?.decimal_place
    );

    blob.then((res) => {
      const data = {
        method: selectedDepositType?.key,
        requestData: {
          amount: Number(blockchainFormik.values.currencyAmount) ,
          coin: selectedWallet?.coin?.id,
          blob: res,
          withdraw_address: blockchainFormik.values.toAddress,
          blockchain: selectedBlockchainNetwork?.id,
          stb_fee: fees?.stb_fee,
          handler_fee: fees?.handler_fee,
        },
      };
      console.log(res, "asdadsa");
      createWithdraw(data);
    }).catch(err => {
      setCreateWithdrawLoading(false)
    });
  };

  const createWithdraw = async (data) => {
    setCreateWithdrawLoading(true);
    try {
      const response = await merchantApi.post(
        `${WITHDRAW}${data?.method}/`,
        data?.requestData
      );
      toast.success(response?.data?.message || "Request created successfully");
      handleCloseModal();
      setCreateWithdrawLoading(false);
      console.log("created res", response);
    } catch (error) {
      setCreateWithdrawLoading(true);
      toast.error(error.data.data.message || "Request failed, try again later");
    }
  };

  const setEnteredAmountConvert = (amount, isLocalCurrency) => {
    // getFees(amount);
    if (isLocalCurrency) {
      const newValue = parseFloat(amount) / parseFloat(selectedWallet?.coin?.rate);
      if (isNaN(newValue)) {
        blockchainFormik.setFieldValue("localCurrencyAmount", 0);
        blockchainFormik.setFieldValue("currencyAmount", 0);
        // setCurrencyAmount(0);
        // setLocalCurrencyAmount(0);
      } else {
        console.log(newValue, amount);
        blockchainFormik.setFieldValue("localCurrencyAmount", amount);
        blockchainFormik.setFieldValue("currencyAmount", newValue.toFixed(selectedWallet?.coin?.decimal_place));
        // setLocalCurrencyAmount(`${amount}`);
        // setCurrencyAmount(newValue.toFixed(8));
      }
    } else {
      const newValue = parseFloat(amount) * parseFloat(selectedWallet?.coin?.rate);
      if (isNaN(newValue)) {
        blockchainFormik.setFieldValue("localCurrencyAmount", 0);
        blockchainFormik.setFieldValue("currencyAmount", 0);
        // setLocalCurrencyAmount(0);
        // setCurrencyAmount(0);
      } else {
        blockchainFormik.setFieldValue("currencyAmount", amount);
        blockchainFormik.setFieldValue("localCurrencyAmount", newValue.toFixed(selectedWallet?.coin?.decimal_place));
        // setCurrencyAmount(`${amount}`);
        // setLocalCurrencyAmount(newValue.toFixed(8));
      }
    }
  };
  const getFees = useCallback(async (amount, signal) => {
    try {
      const response = await merchantApi.post(WITHDRAW_FEE, {
        coin: selectedWallet?.coin?.id,
        amount: Number(amount)
        
      },{signal} )
      console.log('FEEEEEEEE',response)
      setFees({...response?.data?.data})
    } catch (error) {
      console.log('errrr',error)
      if(error?.error?.code === 'ERR_CANCELED') {

      } else {

        toast.error(error?.data?.data?.message||'Could not retrieve fees, try again later')
      }
    }
  },[selectedWallet])

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if(blockchainFormik?.values?.currencyAmount > 0) {
      getFees( blockchainFormik?.values?.currencyAmount, signal);
    } else {

    }
    return () => {
      controller.abort();
    };
  }, [
  blockchainFormik.values.currencyAmount
  ]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if(interacFormik?.values?.amount > 0) {
      getFees( interacFormik?.values?.amount, signal);
    } else {

    }
    return () => {
      controller.abort();
    };
  }, [
  interacFormik.values.amount
  ]);




  let content = "";
  let title = "";
  console.log("step", step);
  switch (step) {
    case 1: {
      title = "Select Store";
      content = (
        <>
          <DialogContent sx={{ paddingTop: "5px !important" }}>
            <Stack spacing={2} direction={"column"} alignItems={"center"}>
              <TextField
                disabled={walletFetchingLoading}
                select
                fullWidth
                variant="outlined"
                className=" bg-white"
                id="store"
                name="store"
                InputLabelProps={textFieldLabelProps}
                label="Store"
                value={selectedStore}
                onChange={(event) => {
                  handleStorechange(event);
                }}
                onBlur={(event) => {
                  //   interacFormik.handleChange(event);
                }}
              >
                {storeOption.map((a, i) => (
                  <MenuItem value={a.store_id}>{a.name}</MenuItem>
                ))}
              </TextField>
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary>Close</ButtonSecondary>

            <ButtonPrimary
              onClick={() => {
                if (step === "interac") {
                  // interacSubmit();
                } else {
                  handleCloseModal();
                }
              }}
            >
              Next
            </ButtonPrimary>
          </DialogActions>
        </>
      );

      break;
    }

    case 2: {
      title = "Select Coin";
      content = (
        <>
          <DialogContent sx={{ position: "relative" }}>
            <Stack
              style={{
                paddingTop: "5px",
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              }}
            >
              <TextField
                className="w-100 mb-3"
                required
                variant="outlined"
                name="search_coin"
                id="search_coin"
                InputLabelProps={textFieldLabelProps}
                label="Search Coin"
                type="text"
                value={coinSearchTerm}
                onChange={(e) => {
                  setCoinSearchTerm(e.target.value);
                }}
              />
            </Stack>

            <Stack
              direction={"row"}
              justifyContent={"center"}
              flexWrap={"wrap"}
              gap={1.5}
              paddingTop={"10px"}
            >
              {!walletFetchingLoading ? walletList
                ?.filter((wallet) => {
                  console.log(wallet);
                  if (coinSearchTerm.trim() === "") {
                    return wallet;
                  }
                  return wallet?.coin?.symbol
                    ?.toLowerCase()
                    .includes(coinSearchTerm?.trim()?.toLowerCase()) || wallet?.coin?.name
                    ?.toLowerCase()
                    .includes(coinSearchTerm?.trim()?.toLowerCase());
                })
                .map((item) => (
                  <Stack
                    className="method-card"
                    direction={"row"}
                    gap={"1rem"}
                    onClick={() => handleSelectWallet(item)}
                    key={item?.id}
                  >
                    <Stack>
                      <img
                        src={item?.coin?.logo}
                        alt="deposit type"
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                      />
                    </Stack>
                    <Stack
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      direction={"row"}
                      width={"100%"}
                    >
                      <Stack justifyContent={"space-evenly"}>
                        <Typography variant="subtitle1" className="title">
                          {item?.coin?.name}
                        </Typography>
                        <Typography
                          variant="caption"
                          className="desc"
                          // color={statusColors?.colors?.complete}
                        >
                          {`1 ${item?.coin?.symbol} = ${item?.coin?.rate.toFixed(
                            2
                          )} ${item?.currency?.code}`}
                        </Typography>
                      </Stack>
                      <Stack>
                        <Typography
                          variant="subtitle2"
                          className="title"
                          color={statusColors?.colors?.complete}
                        >
                          {formatCurrency(
                            item?.balance,
                            2,
                            item?.coin?.symbol,
                            item?.coin?.symbol_prefix
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                )) : new Array(5).fill(0).map((item, index) => {
                  return <Skeleton variant="rounded" width={'100%'} height={80} />
                })}
             
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary
              onClick={() => {
                if (isSingleWallet) {
                  handleCloseModal();
                } else {
                  setStep((step) => step - 1);
                }
              }}
            >
              Back
            </ButtonSecondary>
          </DialogActions>
        </>
      );
      break;
    }

    case 3: {
      title = (
        <Stack direction={"column"}>
          Select Withdraw Method
          <Typography variant="caption">
            for {selectedWallet?.coin?.name}
          </Typography>
        </Stack>
      );
      console.log("slctdwlt", selectedWallet);
      content = (
        <>
          <DialogContent sx={{ paddingTop: "5px !important" }}>
            <Stack spacing={2} direction={"column"} alignItems={"center"}>
              <>
                {selectedWallet?.coin?.deposit_types.map((item) => (
                  <Stack
                    className="method-card"
                    direction={"row"}
                    gap={"1rem"}
                    onClick={() => handleSelectDepositType(item)}
                    key={item?.id}
                  >
                    <Stack>
                      <img
                        src={item?.logo}
                        alt="deposit type"
                        style={{
                          width: "80px",
                          height: "50px",
                          objectFit: "contain",
                        }}
                      />
                    </Stack>
                    <Stack>
                      <Typography variant="subtitle1" className="title">
                        {item?.name}
                      </Typography>
                      <Typography variant="caption" className="desc">
                        {item?.description}
                      </Typography>
                    </Stack>
                  </Stack>
                ))}
              </>
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary
              onClick={() => {
                setStep((step) => step - 1);
              }}
            >
              Back
            </ButtonSecondary>
          </DialogActions>
        </>
      );
      break;
    }

    case 4: {
      title = (
        <Stack direction={"column"}>
          Select Blockchain Network
          <Typography variant="caption">
            for {selectedWallet?.coin?.name}
          </Typography>
        </Stack>
      );
      console.log("step 444444", selectedWallet, selectedDepositType);
      content = (
        <>
          <DialogContent sx={{ paddingTop: "5px !important" }}>
            <Stack spacing={2} direction={"column"} alignItems={"center"}>
              <>
                {selectedWallet?.coin?.supported_deposit_blockchains.map(
                  (item) => (
                    <Stack
                      className="method-card"
                      direction={"row"}
                      gap={"1rem"}
                      onClick={() => handleSelectBlockchainNetwork(item)}
                      key={item?.id}
                    >
                      <Stack>
                        <img
                          src={item?.logo}
                          alt="deposit type"
                          style={{
                            width: "80px",
                            height: "50px",
                            objectFit: "contain",
                          }}
                        />
                      </Stack>
                      <Stack>
                        <Typography variant="subtitle1" className="title">
                          {item?.name}
                        </Typography>
                        <Typography variant="caption" className="desc">
                          {item?.description}
                        </Typography>
                      </Stack>
                    </Stack>
                  )
                )}
              </>
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary
              onClick={() => {
                if (selectedWallet?.coin?.deposit_types?.length === 1) {
                  setStep(2);
                } else {
                  setStep((step) => step - 1);
                }
              }}
            >
              Back
            </ButtonSecondary>
          </DialogActions>
        </>
      );

      break;
    }

    case "interac": {
      title = (
        <Stack direction={"column"}>
          Withdraw Funds
          <Typography variant="caption">via Interac e-Transfer®</Typography>
        </Stack>
      );
      content = (
        <>
          <DialogContent sx={{ paddingTop: "5px !important" }}>
            <Stack spacing={2} direction={"column"} alignItems={"center"}>
              <Stack spacing={1} direction={"column"} alignItems={"center"}>
                <TextField
                  className="w-100 mb-3"
                  required
                  variant="outlined"
                  name="amount"
                  id="amount"
                  InputLabelProps={textFieldLabelProps}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {selectedWallet?.coin?.symbol}
                      </InputAdornment>
                    ),
                  }}
                  label="Withdraw Amount"
                  type="text"
                  value={interacFormik.values.amount}
                  onChange={(e) => {
                    // getFees(e.target.value)
                    interacFormik.handleChange(e)
                  }}
                  onBlur={interacFormik.handleBlur}
                  error={
                    interacFormik.touched.amount &&
                    Boolean(interacFormik.errors.amount)
                  }
                  helperText={
                    interacFormik.touched.amount && interacFormik.errors.amount
                  }
                />
                <TextField
                  className="w-100 mb-3"
                  required
                  variant="outlined"
                  name="toAddress"
                  id="toAddress"
                  InputLabelProps={textFieldLabelProps}
                  label="Destination Address"
                  type="text"
                  value={interacFormik.values.toAddress}
                  onChange={interacFormik.handleChange}
                  onBlur={interacFormik.handleBlur}
                  error={
                    interacFormik.touched.toAddress &&
                    Boolean(interacFormik.errors.toAddress)
                  }
                  helperText={
                    interacFormik.touched.toAddress &&
                    interacFormik.errors.toAddress
                  }
                />
                <InteracDetailsCard
                  balance={formatCurrency(
                    selectedWallet?.balance,
                    selectedWallet?.coin?.decimal_place,
                    selectedWallet?.coin?.symbol,
                    selectedWallet?.coin?.symbolPrefix
                  )}
                  wallet = {selectedWallet}
                  // rate={`1 ${selectedWallet?.coin?.symbol} =${selectedWallet?.coin?.rate} ${selectedWallet?.currency?.code}`}
                  // network={selectedBlockchainNetwork.name}
                  amount = {interacFormik.values.amount}
                  stbFee = {fees?.stb_fee}
                  handlerFee = {fees?.handler_fee}
                />
                <TextField
                  className="w-100 mb-3"
                  multiline
                  rows={4}
                  variant="outlined"
                  name="description"
                  id="description"
                  InputLabelProps={textFieldLabelProps}
                  label="Description"
                  type="text"
                  value={interacFormik.values.description}
                  onChange={interacFormik.handleChange}
                  onBlur={interacFormik.handleBlur}
                  error={
                    interacFormik.touched.description &&
                    Boolean(interacFormik.errors.description)
                  }
                  helperText={
                    interacFormik.touched.description &&
                    interacFormik.errors.description
                  }
                />
                <Stack direction={"row"} alignItems={"center"}>
                  <IconButton>
                    <WarningAmberIcon color="error" fontSize="medium" />
                  </IconButton>
                  <Typography
                    variant="caption"
                    sx={{ color: "#d32f2f", lineHeight: "110%" }}
                  >
                    INTERAC e-Transfer requests must be sent from a bank account
                    in the same name as your STB account. Funds sent from a bank
                    account under any other name will be held pending ID
                    verification.
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  style={{ marginTop: "1rem" }}
                >
                  <IconButton>
                    <AccessTimeIcon color="action" fontSize="medium" />
                  </IconButton>
                  <Typography variant="caption" style={{ lineHeight: "110%" }}>
                    If funds don't go through after an hour of accepting the
                    transfer, please call your bank for further inquiries before
                    contacting support.
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary
              disabled={verificationLoading}
              onClick={() => {
                interacFormik.resetForm();
                if (selectedWallet?.coin?.deposit_types?.length === 1) {
                  setStep(2);
                } else {
                  setStep(3);
                }
              }}
            >
              Back
            </ButtonSecondary>
            <ButtonPrimary
              loading={verificationLoading}
              onClick={interacSubmit}
            >
              Withdraw
            </ButtonPrimary>
          </DialogActions>
        </>
      );
      break;
    }
    case "blockchain": {
      title = (
        <Stack direction={"column"}>
          Withdraw Funds
          <Typography variant="caption">
            via {selectedBlockchainNetwork.name}
          </Typography>
        </Stack>
      );
      content = (
        <>
          <DialogContent sx={{ paddingTop: "5px !important" }}>
            <Stack spacing={2} direction={"column"} alignItems={"center"}>
              <TextField
                className="w-100 mb-3"
                required
                variant="outlined"
                name="amount"
                id="amount"
                InputLabelProps={textFieldLabelProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {selectedWallet?.coin?.symbol}
                    </InputAdornment>
                  ),
                }}
                label="Withdraw Amount"
                type="text"
                value={blockchainFormik.values.currencyAmount}
                onChange={(e) => {
                  setEnteredAmountConvert(e.target.value,false);
                  blockchainFormik.handleChange(e)}}
                onBlur={blockchainFormik.handleBlur}
                error={
                  blockchainFormik.touched.currencyAmount &&
                  Boolean(blockchainFormik.errors.currencyAmount)
                }
                helperText={
                  blockchainFormik.touched.currencyAmount && blockchainFormik.errors.currencyAmount
                }
              />
              <IconButton
                sx={{ transform: "rotate(90deg)", marginBottom: "1rem !important" }}
              >
                <SyncAltRoundedIcon fontSize="large" />
              </IconButton>
              <TextField
                className="w-100 mb-3"
                required
                variant="outlined"
                name="amount"
                id="amount"
                InputLabelProps={textFieldLabelProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {selectedWallet?.currency?.code}
                    </InputAdornment>
                  ),
                }}
                label="Withdraw Amount"
                type="text"
                value={blockchainFormik.values.localCurrencyAmount}
                onChange={(e) => {
                  setEnteredAmountConvert(e.target.value,true);
                  blockchainFormik.handleChange(e)}}
                onBlur={blockchainFormik.handleBlur}
                error={
                  blockchainFormik.touched.localCurrencyAmount &&
                  Boolean(blockchainFormik.errors.localCurrencyAmount)
                }
                helperText={
                  blockchainFormik.touched.localCurrencyAmount && blockchainFormik.errors.localCurrencyAmount
                }
              />
              <TextField
                className="w-100 mb-3"
                required
                variant="outlined"
                name="toAddress"
                id="toAddress"
                InputLabelProps={textFieldLabelProps}
                label="Destination Address"
                type="text"
                value={blockchainFormik.values.toAddress}
                onChange={blockchainFormik.handleChange}
                onBlur={blockchainFormik.handleBlur}
                error={
                  blockchainFormik.touched.toAddress &&
                  Boolean(blockchainFormik.errors.toAddress)
                }
                helperText={
                  blockchainFormik.touched.toAddress &&
                  blockchainFormik.errors.toAddress
                }
              />
              <BlockchainWithdrawDetailsCard
                balance={formatCurrency(
                  selectedWallet?.balance,
                  selectedWallet?.coin?.decimal_place,
                  selectedWallet?.coin?.symbol,
                  selectedWallet?.coin?.symbolPrefix
                )}
                wallet = {selectedWallet}
                rate={`1 ${selectedWallet?.coin?.symbol} =${selectedWallet?.coin?.rate} ${selectedWallet?.currency?.code}`}
                network={selectedBlockchainNetwork.name}
                amount = {blockchainFormik.values.currencyAmount}
                stbFee = {fees?.stb_fee}
                handlerFee = {fees?.handler_fee}
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary
              disabled={verificationLoading}
              onClick={() => {
                blockchainFormik.resetForm();
                if (
                  selectedWallet?.coin?.supported_deposit_blockchains
                    ?.length === 1
                ) {
                  if (selectedWallet?.coin?.deposit_types?.length === 1) {
                    setStep(2);
                  } else {
                    setStep(3);
                  }
                } else {
                  setStep(4);
                }
              }}
            >
              Back
            </ButtonSecondary>
            <ButtonPrimary
              loading={verificationLoading}
              onClick={blockchainSubmit}
            >
              Withdraw
            </ButtonPrimary>
          </DialogActions>
        </>
      );
      break;
    }
    case "passphrase": {
      title = <Stack direction={"column"}>Enter Passphrase</Stack>;
      content = (
        <>
          <DialogContent sx={{ paddingTop: "5px !important" }}>
            <Stack spacing={2} direction={"column"} alignItems={"center"}>
              <TextField
                className="w-100 mb-3"
                style={{ width: "100%" }}
                required
                variant="outlined"
                name="passphrase"
                id="passphrase"
                type={showPassphrase ? "text" : "password"}
                InputLabelProps={textFieldLabelProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={toggleShowPassphrase}
                    >
                      {showPassphrase ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
                label="Passphrase"
                value={passphraseFormik.values.passphrase}
                onChange={passphraseFormik.handleChange}
                onBlur={passphraseFormik.handleBlur}
                error={
                  passphraseFormik.touched.passphrase &&
                  Boolean(passphraseFormik.errors.passphrase)
                }
                helperText={
                  passphraseFormik.touched.passphrase &&
                  passphraseFormik.errors.passphrase
                }
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              paddingX: "24px",
              paddingBottom: "15px",
            }}
          >
            <ButtonSecondary
              disabled={createWithdrawLoading}
              onClick={() => {
                if (selectedDepositType?.key === "interac") {
                  setStep("interac");
                } else {
                  setStep(3);
                }
              }}
            >
              Back
            </ButtonSecondary>
            <ButtonPrimary
              loading={createWithdrawLoading}
              onClick={() => {
                if(selectedDepositType?.key === "interac") {
                  interacPassphraseSubmit();
                } else {
                  blockchainPassphraseSubmit()
                }
              }}
            >
              Request Withdraw
            </ButtonPrimary>
          </DialogActions>
        </>
      );
      break;
    }
    default: {
      content = "";
    }
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={"xs"}
      onClose={handleCloseModal}
      scroll={"paper"}
      BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
      PaperProps={{
        style: {
          boxShadow: "none",
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "" }}>{title}</DialogTitle>
      {/* <Typography variant="subtitle1"></Typography> */}
      {content}
    </Dialog>
  );
};

function mapStateToProps({ authReducer }) {
  const { userPayString } = authReducer;
  return {
    userPayString,
  };
}

export default connect(mapStateToProps, null)(WithdrawModal);
