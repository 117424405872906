import React, { useState, useEffect } from 'react';
import { Button } from 'rsuite';
import { Col, Row } from '@themesberg/react-bootstrap';
import {
  TextField,
} from '@material-ui/core';
import InfoRounded from '@material-ui/icons/InfoRounded';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
// import WithdrawOtpDialog from "./WithdrawOtpDialog";
// import WalletSelect from "./WalletSelect";

// import { send } from '../../../../../utils/xrpl_functions';

// const validationSchema = yup.object({
//   amountCAD: yup
//     .number('Enter your amount in CAD')
//     .moreThan(0)
//     .required('Amount in CAD is required'),

//   address: yup
//     .string('Enter your Remark')
//     .min(5, 'To Short')
//     .max(300, 'To Long ')
//     .required('Remark is required'),

//   amountBTC: yup
//     .number('Enter Amount in BTC')
//     .moreThan(0)
//     .required('Amount is required'),
// });

function RefundDialog({
  open,
  handleSendRefund,
  onClose: handleClose,
  loading,
  error,
  title,
  transactionData,
  userRole,
  userCurrency
}) {

  console.log('trabssssss', transactionData)
  console.log(userCurrency)
  const [data, setdata] = useState({
    amountBTC: 0,
    amountCAD: 0,
    address: '',
    userInputCurrencyValue: 0,
    userInputCoinValue: 0,
  });

  const [isDisabled, setIsDisabled] = useState(false);
  const [random, setRandom] = useState();
  const {
    store_id: estore_id = [],
    name: ename = [],
    p: ephone = [],
    postal_code: epostal_code = [],
    address: eddress = [],
  } = error;

  const validationSchema = yup.object({
    amountCAD: yup
      .number(`Enter your amount in ${userCurrency.symbol}`)
      .moreThan(
        0,
        `${transactionData.currency.code} value must be greater than 0`
      )

      .required(`Amount in ${userCurrency.symbol} is required`),

    address: yup
      .string('Enter your Remark')
      .min(5, 'Too Short')
      .max(300, 'Too Long ')
      .required('Remark is required'),

    amountBTC: yup
      .number('Enter Amount in BTC')
      .moreThan(
        0,
        `${transactionData.receiver_coin.symbol} value must be greater than 0`
      )
      .max(
        Number(transactionData.receive_amount).toFixed(
          transactionData.receiver_coin.decimal_place
        ),
        'Cannot be more than transaction value'
      )
      .required('Amount is required'),
  });

  const onSubmit = async (values) => {
    const value = {
      ...values,
    };

    const finalData = {
      from: transactionData.receiver.xrp_address,
      to: transactionData.sender.xrp_address,
      amount: values.amountBTC,
      coin: transactionData?.receiver_coin.id,
      coinSymbol: transactionData.receiver_coin.symbol,
      issuer: transactionData.receiver_coin.issuer_address,
      type: 'refund',
      store_id: transactionData?.store?.store_id,
      transaction: transactionData?.id,
      decimalPlace: transactionData.receiver_coin.decimal_place,
    };
    // if (userRole === 'administrator') {
    //   finalData['blob'] = transactionData?.blob;
    // }

    handleSendRefund(finalData);
  };

  const handleChange = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value });
  };
  const formik = useFormik({
    initialValues: data,
    validateOnBlur: true,
    onSubmit,
    handleChange,
    validationSchema: validationSchema,
  });

  const coinValueChangeHandler = (e) => {
    const coinValue = e.target.value;
    console.log(e.target.value);
    formik.setValues({
      ...formik.values,
      amountBTC: coinValue,
      amountCAD: Number(coinValue * transactionData?.receiver_coin?.rate).toFixed(
        transactionData.currency.decimal_place
      ),
    });
    console.log(formik.values);
  };

  const currencyValueChangeHandler = (e) => {
    const currencyValue = e.target.value;
    formik.setValues({
      ...formik.values,
      amountBTC: Number(
        (currencyValue / transactionData?.receiver_coin?.rate).toFixed(
          transactionData.receiver_coin.decimal_place
        )
      ),
      amountCAD: currencyValue,
    });
    console.log(formik.values);
  };

  useEffect(() => {
    formik.values.amountCAD = Number(
      transactionData.receive_amount * transactionData?.receiver_coin?.rate
    ).toFixed(transactionData.currency.decimal_place);
    formik.values.amountBTC = Number(transactionData.receive_amount).toFixed(
      transactionData.receiver_coin.decimal_place
    );
    setRandom(Math.random());
  }, []);

  return (
    <>
      <Dialog open={open} maxWidth={'sm'} onClose={handleClose}  BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '628px',
          minHeight: '450px',
          maxHeight: '90vh',
        },
      }}>
        <DialogTitle className="text-center">
        <p className="dialog-title">{title}</p>
        </DialogTitle>
        <hr className="hr" />

        <form onSubmit={() => {}}>
        <DialogContent className="flex-col">
            <Row>
              <Col xl={12} xs={12}>
                <p
                  className="font-fam font-600 mb-3"
                  style={{ textAlign: 'center' }}
                >{`You are initiating refund against transaction with user ${
                  transactionData?.receiver?.name
                } of Value ${Number(transactionData?.receive_amount).toFixed(
                  transactionData.receiver_coin.decimal_place
                )}${' '}${transactionData?.receiver_coin?.symbol} `}
                </p>
              </Col>
              <>
                <Col xl={12} xs={12}>
                  <TextField
                    className="w-100 mb-3"
                    name="amountCAD"
                    id="amountCAD"
                    label={`Amount in ${userCurrency.symbol}`}
                    type="text"
                    variant="outlined"
                    value={formik.values.amountCAD}
                    onChange={currencyValueChangeHandler}
                    onBlur={formik.handleBlur}
                    error={
                      (formik.touched.amountCAD &&
                        Boolean(formik.errors.amountCAD)) ||
                      [...eddress].length > 0
                    }
                    helperText={
                      (formik.touched.amountCAD && formik.errors.amountCAD) ||
                      [...eddress].join(',')
                    }
                  />
                </Col>
                <Col xl={12} xs={12}>
                  <TextField
                    className="w-100 mb-3"
                    name="amountBTC"
                    id="amountBTC"
                    label={`Amount in ${transactionData.receiver_coin.symbol}`}
                    type="text"
                    variant="outlined"
                    value={formik.values.amountBTC}
                    onChange={coinValueChangeHandler}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.amountBTC &&
                      Boolean(formik.errors.amountBTC)
                    }
                    helperText={
                      formik.touched.amountBTC && formik.errors.amountBTC
                    }
                  />
                </Col>
                <Col xl={12} xs={12}>
                  <TextField
                    style={{ height: 100 }}
                    className="w-100 mb-3"
                    name="address"
                    id="address"
                    label="Remark"
                    type="text"
                    variant="outlined"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      (formik.touched.address &&
                        Boolean(formik.errors.address)) ||
                      [...ephone].length > 0
                    }
                    helperText={
                      (formik.touched.address && formik.errors.address) ||
                      [...ephone].join(',')
                    }
                  />
                </Col>
              </>
            </Row>
    
        </DialogContent>
        {!isDisabled ? (
          <DialogActions>
            <Button  className="btn white-btn action-button basis-30" onClick={handleClose} appearance="subtle">
              Cancel
            </Button>
            <Button  className={`btn action-button primary_color text-white basis-70 ${
              !formik.isValid ? 'disabled' : null
            }`}
            appearance="primary"
            onClick={formik.handleSubmit}
            loading={loading}
            >  
              {!loading ? 'Next' : 'Loading...'}
            </Button>          
          </DialogActions>
       
        ) : (
          <>
            <br />
            <p className="font-700 font-fam text-red text-center mb-3">
              CAN NOT INITIATE REFUND, INVALID AMOUNT
            </p>
          </>
        )}
        </form>
      </Dialog>
    </>
  );
}

export default RefundDialog;
