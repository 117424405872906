import { combineReducers } from "redux";
import profileReducer from "./profile";
import kybReducer from "./kybflow";
import businessReducer from "./business";
import authReducer from "./auth";
import storeReducer from "./store";

export default combineReducers({
  profileReducer,
  kybReducer,
  businessReducer,
  authReducer,
  storeReducer,
});
